import { Button, Form, Input, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { addAccountsToOrg } from "../requests";
import { useState } from "react";

const AccountForm = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [disabled, setDisabled] = useState(false)

  const onFinish = (values) => {
    if(values.accounts){
      var accountsToSend = values.accounts.filter((account) => {return account !== undefined})
      if(accountsToSend.length > 0) {
        setDisabled(true)
        messageApi.open({
          type: "loading",
          content: "submitting accounts",
          duration: 0
        })
        console.log('Received values of form:', values);
        addAccountsToOrg(values.accounts, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw).then(
          (resp) => {
            console.log("resp", resp)
            messageApi.destroy()
            setDisabled(false)
          }
        )
      } else {
        console.log("no undefined fields added")
      }
    } else {
      console.log("no fields added")
    }
    
  };
  console.log("generating the form")
  return (
  <Form
    name="dynamic_form_complex"
    autoComplete="off"
    onFinish={onFinish}
    disabled={disabled}
    style={{
      maxWidth: 600,
      margin: "auto",
      marginTop: "10px"
    }}>
    <Form.List name="accounts">
      {(fields, {add, remove}, {errors}) => (
        <>
          {fields.map((field, index) => <>
              <Form.Item key={index}>
                <Form.Item {...field} noStyle>
                  <Input style={{width: '60%'}} placeholder="accountid"></Input>
                </Form.Item>
                <MinusCircleOutlined
                  style={{marginLeft: "5"}}
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              </Form.Item>
            </>
          )}
          {contextHolder}
          <Button
            type="dashed"
            onClick={() => add()}
            style={{
              width: '60%',
            }}
            icon={<PlusOutlined />}
          >
            Add another account
          </Button>
        </>
      )}
    </Form.List>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>)
}

const AddAccount = (props) => {
  if(props.common.project.selectedProject === null) {
    return (<div>
      Please select an Organization
    </div>)
  } else {
    console.log("Generating account form")
    return AccountForm(props)
  }
}

export default AddAccount;