import { Button, Form, Input, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { addMembersToOrg } from "../requests";
import { useState } from "react";

const AddMembers = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [disabled, setDisabled] = useState(false)

  const onFinish = (values) => {
    if(values.users){
      var usersToSend = values.users.filter((user) => {return user !== undefined})
      if(usersToSend.length > 0) {
        setDisabled(true)
        messageApi.open({
          type: "loading",
          content: "submitting accounts",
          duration: 0
        })
        console.log('Received values of form:', values);
        addMembersToOrg(values.users, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw).then(
          (status) => {
            console.log("status", status)
            messageApi.destroy()
            setDisabled(false)
          }
        )
      } else {
        console.log("no undefined fields added")
      }
    } else {
      console.log("no fields added")
    }
    
  };
  console.log("generating the form")
  return (
  <Form
    name="dynamic_form_complex"
    autoComplete="off"
    onFinish={onFinish}
    disabled={disabled}
    style={{
      maxWidth: 600,
      margin: "auto",
      marginTop: "10px"
    }}>
    <Form.List name="users">
      {(fields, {add, remove}, {errors}) => (
        <>
          {fields.map((field, index) => <>
              <Form.Item key={index}>
                <Form.Item {...field} noStyle>
                  <Input style={{width: '60%'}} placeholder="user email"></Input>
                </Form.Item>
                <MinusCircleOutlined
                  style={{marginLeft: "5"}}
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              </Form.Item>
            </>
          )}
          {contextHolder}
          <Button
            type="dashed"
            onClick={() => add()}
            style={{
              width: '60%',
            }}
            icon={<PlusOutlined />}
          >
            Add Email
          </Button>
        </>
      )}
    </Form.List>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>)
}

export default AddMembers;