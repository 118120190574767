import { useAuth0 } from "@auth0/auth0-react";
import { useState } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Home from "./pages/Home"
import Scheudles from "./pages/Schedules"
import AccountsData from "./pages/AccountsData"
import Profile from "./components/Profile";
import { retrieveUserOrgs, retrieveUserScopes } from "./requests"
import { Layout, Menu } from 'antd';
import { Col, Row } from 'antd';
import { Image } from "antd";
import ProjectSelection from "./components/Project";
import AddAccount from "./pages/AddAccount";
import AddSchedule from "./pages/AddSchedule";
import ProjectDefaults from "./pages/ProjectDefaults";
import AddMembers from "./pages/AddMembers";
import MembersData from "./pages/MembersData";

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const createMenuLinkItem = (link, text) => {
  return <Link to={link}><span>{text}</span></Link>
}

const handleMenuItemClick = (it) => {
  console.log("handleMenuItemClick", it.key)
}

const should_present_options = (isAuthenticated, selectedProject, userScopes) => {
  return isAuthenticated && 
    selectedProject && 
    userScopes && 
    userScopes.scopes && 
    selectedProject && 
    selectedProject.enabled === true &&
    selectedProject.setup_finished === true &&
    selectedProject.unsubscribed !== true
}

const generateMenuItems = (isAuthenticated, selectedProject, userScopes, common_variables) => {
  var res = {items: []}
  var its = {items: []}

  // console.log("generateMenuItems", isAuthenticated, selectedProject)
  its.items.push(getItem(createMenuLinkItem("/", "Home"), "home"))
  if(should_present_options(isAuthenticated, selectedProject, userScopes)) {
    if(userScopes.scopes.includes("read:project") || userScopes.scopes.includes("manage:project_members") || userScopes.scopes.includes("write:project_members")){
      var project_sub_items = []
      if (userScopes.scopes.includes("read:project")) {
        project_sub_items.push(getItem(createMenuLinkItem("/project/defaults", "Defaults"), "projectdefaults"))
      }
      if (userScopes.scopes.includes("manage:project_members")) {
        project_sub_items.push(getItem(createMenuLinkItem("/project/members", "View Members"), "viewprojectmembers"))
      }
      if (userScopes.scopes.includes("write:project_members")) {
        project_sub_items.push(getItem(createMenuLinkItem("/project/members/invite", "Add Members"), "addprojectmembers"))
      }
      its.items.push(getItem("Project Settings", "project", null, project_sub_items))
    }
    if(userScopes.scopes.includes("read:accounts") || userScopes.scopes.includes("write:accounts")){
      var acc_sub_items = []
      if(userScopes.scopes.includes("read:accounts")) {
        acc_sub_items.push(getItem(createMenuLinkItem("/accounts", "Manage"), "manageaccounts"))
      }
      if(userScopes.scopes.includes("write:accounts")) {
        acc_sub_items.push(getItem(createMenuLinkItem("/accounts/add", "Add"), "addaccounts"))
      }
      its.items.push(getItem("Accounts", "accounts", null, acc_sub_items))
    }
    if(userScopes.scopes.includes("read:schedules") || userScopes.scopes.includes("write:schedules")){
      var sched_sub_items = []
      if(userScopes.scopes.includes("read:schedules")) {
        sched_sub_items.push(getItem(createMenuLinkItem("/schedules", "Manage"), "manageschedules"))
      }
      if(userScopes.scopes.includes("write:schedules")) {
        sched_sub_items.push(getItem(createMenuLinkItem("/schedules/add", "Add"), "addschedules"))
      }
      its.items.push(getItem("Schedules", "schedules", null, sched_sub_items))
    }
    if(common_variables.project.selectedProject && common_variables.project.selectedProject.enabled === true) {
      const support_mailto = "mailto:support@skysaver.io"
      its.items.push(getItem(<a href={support_mailto}>Contact Support</a>))
    }
  }
  
  res.default = "home"
  console.log("generateMenuItems")
  console.log(res)
  return its
}

const generateRoutes = (isAuthenticated, common_variables, userScopes) => {
  var ret = []
  ret.push(<Route key="home" path="/" element={<Home common={common_variables}/>}></Route>)
  console.log("generateRoutes.isAuthenticated", isAuthenticated)
  console.log("generateRoutes.userScopes", userScopes)
  console.log("generateRoutes.common_variables", common_variables)
  if(isAuthenticated && userScopes && userScopes.scopes && common_variables.project.selectedProject && common_variables.project.selectedProject.enabled === true ) {
    console.log("generateRoutes.userScopes.scopes", userScopes.scopes)
    if(userScopes.scopes.includes("read:accounts")){
      ret.push(<Route key="accounts" path="/accounts" element={<AccountsData common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("write:accounts")){
      ret.push(<Route key="addaccounts" path="/accounts/add" element={<AddAccount common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("read:schedules")){
      ret.push(<Route key="schedules" path="/schedules" element={<Scheudles common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("write:schedules")){
      ret.push(<Route key="addschedules" path="/schedules/add" element={<AddSchedule common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("read:project")){
      ret.push(<Route key="projectdefaults" path="/project/defaults" element={<ProjectDefaults common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("manage:project_members")){
      ret.push(<Route key="viewprojectmembers" path="/project/members" element={<MembersData common={common_variables}/>}></Route>)
    }
    if(userScopes.scopes.includes("write:project_members")) {
      ret.push(<Route key="addprojectmembers" path="/project/members/invite" element={<AddMembers common={common_variables}/>}></Route>)
    }
  }
  console.log(ret)
  return ret
}

async function setUserToken(setUserTokenFunc, getIdTokenClaims) {
  const userToken = await getIdTokenClaims()
  console.log("new user token", userToken)
  setUserTokenFunc(userToken)
}

async function retrieveToken(setTokenFunc, getAccessTokenSilently, setUserTokenFunc, getIdTokenClaims, loginWithRedirect) {  
  try {
    const accessToken = await getAccessTokenSilently({
      useRefreshTokensFallback: true,
      authorizationParams: {
        scope:"profile email openid offline_access"
      }
    });
    console.log("new token", accessToken)
    setTokenFunc(accessToken)
    setUserToken(setUserTokenFunc, getIdTokenClaims)
  } catch (error) {
    if (error.message === 'login_required') {
      console.log("error getting the access token, calling the login with redirect")
      loginWithRedirect();
    } else {
      // Handle other errors
    }
  }
}

const App = () => {
  // check if there's a new registree parameter
    // if there's a new registree parameter
      // set the cookie
      // check if the user is logged in
        // if they are logged in, skip
        // if user is not logged in, display a login flow to start creating a new org
  // if there's an aws registree cookie set
    // if the user is logged in
      // display the workflow for creating a new org
        // get org name
        // send request to backend to get the org created and the current user added as an admin
        // delete the cookie
        // refresh
    // if the user is not logged in
      // display a login flow
  // else, do the normal page
  console.log('redrawing the app')
  const [selectedProjectId, setSelectedProject] = useState(localStorage.getItem("skysaver_selected_project_id"));
  console.log("have the following selectedProjectId", selectedProjectId)
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [projectPolling, setProjectPolling] = useState(false)
  const [userProjects, setUserProjects] = useState(null)
  const [projectAccounts, setProjectAccounts] = useState(null)
  const [token, setToken] = useState(null)
  const [userToken, setUserTokenFunc] = useState(null)
  const [userScopes, setUserScopesFunc] = useState(null)
  const { loginWithRedirect } = useAuth0();
  var selectedProject = null
  if (userProjects === null) {
    console.log("setting the user projects")
    retrieveUserOrgs(token, userToken, setUserProjects)
  }
  if (selectedProjectId !== null && userScopes === null) {
    retrieveUserScopes(token, userToken, selectedProjectId, setUserScopesFunc)
  }
  if(selectedProjectId === null && isAuthenticated) {
    console.log("we need to select an org")
  }
  if(isAuthenticated && token === null) {
    console.log("retrieving the access token")
    retrieveToken(setToken, getAccessTokenSilently, setUserTokenFunc, getIdTokenClaims, loginWithRedirect)
  }
  if(userProjects !== null && selectedProjectId !== null) {
    console.log('selecting the project data for id', selectedProjectId, 'from user projects', userProjects)
    selectedProject = userProjects.projects.find((project) => project.id === selectedProjectId)
  }
  console.log("App userProjects: ", userProjects)
  var common_variables = {
    isAuthed: isAuthenticated,
    isLoading: isLoading,
    user: user,
    api_token: token,
    oauth_id_token: userToken,
    scopes: {
      scopes: userScopes,
      setUserScopesFunc: setUserScopesFunc,
    },
    accounts: {
      currentAccounts: projectAccounts,
      setProjectAccountsFunc: setProjectAccounts,
    },
    project: {
      userProjects: userProjects,
      selectedProjectId: selectedProjectId,
      selectedProject: selectedProject,
      setSelectedProjectFunc: setSelectedProject,
      setUserProjectsFunc: setUserProjects
    },
  }
  if (selectedProjectId !== null) {
    localStorage.setItem("skysaver_selected_project_id", selectedProjectId)
    if(projectPolling === null || projectPolling.id !== selectedProjectId) {
      if(projectPolling !== null && projectPolling.id !== selectedProjectId) {
        console.log("clearing the interval", projectPolling.interval)
        clearInterval(projectPolling.interval)
      }
      setTimeout(
        setProjectPolling,
        100,
        {
          id: selectedProjectId,
          interval: setInterval(
            retrieveUserOrgs,
            60000,
            token,
            userToken,
            setUserProjects
          )
        }
      )
    }
  }
  console.log("common_variables", common_variables)
  var data = generateMenuItems(isAuthenticated, selectedProject, userScopes, common_variables)
  return (<div>
    <BrowserRouter>
      <Layout>
        <Header style={{height: '10vh'}}>
          <Row style={{height: "10vh"}}>
            <Col span={6}>
              <Image
                height={'10vh'}
                src="/images/SkySaver_Vertical_Logo.png"
                preview={false}
              >
              </Image>
            </Col>
            <Col span={3} offset={12} style={{marginTop: "3vh", height: "2vh", marginBottom: "3vh"}}><ProjectSelection common={common_variables}/></Col>
            <Col span={1}><div></div></Col>
            <Col span={2} style={{heigh: "100%"}}><Profile common={common_variables}/></Col>
          </Row>
        </Header>
        <Content>
          <Layout>
            <Sider>
              <Menu items={data.items} theme="dark" mode="inline" onClick={handleMenuItemClick} defaultSelectedKeys={data.default} style={{ height: '90vh' }}/>
            </Sider>
            <Content style={{ height: "90vh", overflow: "scroll" }}>
              <Routes>
                {generateRoutes(isAuthenticated, common_variables, userScopes)}
              </Routes>
            </Content>
          </Layout>
        </Content>
      </Layout>
    </BrowserRouter>
  </div>)
}

export default App;